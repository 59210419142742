// import { API_URL } from "../Config/config";

const urls = [
  `/Data.json`,
  //`${API_URL}?host=4&page=1`,
  //`${API_URL}?host=5&page=1`
];

export function fetchTvShowsGrid() {
  return Promise.all(
    urls.map(items => {
      return fetch(items).then(response => response.json());
    })
  );
}
