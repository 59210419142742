// import { API_URL } from "../Config/config";

// const urls = [
//   `${API_URL}search/movie?api_key=${API_KEY}&language=th-TH&query=infinity`,
//   `${API_URL}search/movie?api_key=${API_KEY}&language=th-TH&query=The%20Lion%20King`,
//   `${API_URL}search/movie?api_key=${API_KEY}&language=th-TH&query=how%20to%20train%20your%20dragon%20the`
// ];
const urls = [
  `/Data.json`,
  //`${API_URL}?host=4&page=1`,
  //`${API_URL}?host=5&page=1`
];



export function fetchGrid() {
  return Promise.all(
    urls.map(items => {
      return fetch(items).then(response => response.json());
    })
  );
}
