import { API_URL } from "../Config/config";

const urls = [
  `${API_URL}?host=2&page=10`,
  `${API_URL}?host=1&page=10`,
  `${API_URL}?host=0&page=10`,
];

export function fetchData() {
  return Promise.all(
    urls.map(items => {
      return fetch(items).then(response => response.json());
    })
  );
}

export const fetchAdditionalTVData = id => {
  const url = `${API_URL}`;
  
  return fetch(url).then(response => response.json());
};
