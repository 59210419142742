import { API_URL } from "../Config/config";

// const urls = [
//   `${API_URL}movie/popular?api_key=${API_KEY}&language=th-TH&page=1`,
//   `${API_URL}movie/popular?api_key=${API_KEY}&language=th-TH&page=2`,
//   `${API_URL}movie/popular?api_key=${API_KEY}&language=th-TH&page=3`
// ];
const urls = [
  `${API_URL}?host=0&page=1`,
  `${API_URL}?host=1&page=1`,
  `${API_URL}?host=2&page=1`
];

export const fetchData = () => {
  return Promise.all(
    urls.map(items => {
      return fetch(items).then(response => response.json());
    })
  );
};

export const fetchAdditionalMovieData = id => {
  const url = `${API_URL}`
  return fetch(url).then(response => response.json());
};
